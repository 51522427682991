$(function() {

    initNavigation();
    initCarousel();
    initGallerySlider();

    initMatchHeight();
    initColors();

}); 

function initNavigation() {
    if ($('header nav').length > 0) {
        if ($(window).width() < 992) {
            $('.nav-toggle').click(function() {
                if ($('header nav').hasClass('active')) {
                    $('header nav > ul').removeClass('active');
    
                    if ($('header nav ul li').has('ul').find('ul').hasClass('active')) {
                        $('header nav ul li').has('ul').find('ul').removeClass('active');
                        $('header nav .crab').removeClass('active');
    
                        setTimeout(function() {
                            $('header nav').toggleClass('active');
                        }, 1000);
                    } else {
                        $('header nav').toggleClass('active');
                    }
                } else {
                    $('header nav').toggleClass('active');
                }
            });
            
            $('header nav ul li').each(function() {
                $(this).has('ul').find('a').unbind().click(function() {
                    var parent = $(this).parents('ul');
                    var child = $(this).next('ul');
                    var back = child.find('.back');
                    var crab = $('header nav .crab');
    
                    parent.addClass('active');
                    child.addClass('active');
                    crab.addClass('active');
    
                    back.unbind().click(function() {
                        parent.removeClass('active');
                        child.removeClass('active');
                        crab.removeClass('active');
                    });
                });
            });
        }
    }
}

function initCarousel() {
    if ($('#carousel').length > 0) {
        $('#carousel .carousel').slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            fade: true,
            autoplay: true,
            autoplaySpeed: 5000,
            infinite: true,
            arrows: false,
            dots: false,
            slide: '.slide'
        });
    }
}

function initGallerySlider() {
    if ($('#galleries').length > 0) {
        $('#galleries .gallery-slider').slick({
            mobileFirst: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: false,
            arrows: false,

            responsive: [
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 2
                    }
                },
                {
                    breakpoint: 990,
                    settings: {
                        slidesToShow: 4,
                        arrows: true,
                        prevArrow: '<i class="icon icon-chevron-left prev"></i>',
                        nextArrow: '<i class="icon icon-chevron-right next"></i>'
                    }
                }
            ]
        });
    }
}

function initColors() {
    if ($('.colors').length > 0) {
        $('.colors').each(function() {
            var words = $(this).text().split(' ');
            var parent = $(this);

            $(this).empty();
            words.forEach(function (v, i) {
                parent.append($('<span>').text(v + ' '));
            });
        });
    }
}

function initMatchHeight() {
    $('.matchheight').matchHeight();
}